import { FC } from "react";
import CrossIcon from "../../assets/icons/CrossIcon";
import Button from "../UI/buttons/Button";


interface IProps {
    isOpen: boolean,
    toggleOpen: (open: boolean) => void;

}

const CookieModal: FC<IProps> = ({ isOpen, toggleOpen }) => {
    const COOKIE_LINK = "https://systeme.ru/about/legal/cookie-policy"


    if (!isOpen) return null;

    return (
        <div className="bg-[#3c3c3c] fixed w-[100%] h-[84px] bottom-0 flex justify-center text-[14px]">
            <div className="w-[1200px] flex px-[20px] items-center justify-between">
                <div className="w-[650px] text-[#787f86]">
                    Мы используем cookie-файлы для улучшения предоставляемых услуг.
                    Продолжая навигацию по сайту, вы соглашаетесь
                    <a href={COOKIE_LINK} className="text-[#00ac86]"> с правилами использования cookie-файлов</a>.
                </div>
                <div className="flex items-center gap-[60px] max-[850px]:gap-[5px] max-[850px]:flex-col">
                    <Button size="small" noFish variant="primary" onClick={() => toggleOpen(true)}>Принять</Button>
                    <button onClick={() => toggleOpen(false)}><CrossIcon size={20} className="text-white cursor-pointer" /></button>
                </div>
            </div>
        </div>
    );
};

export default CookieModal;
