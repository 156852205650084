import { ICategoryObject } from "../interfaces/IPartner";

export enum PARTNER_TYPE_NAME {
  Exchange = "По бирже",
  Basket = "По корзине",
  BasketExchange = "По корзине и бирже"
}

export enum PARTNER_TYPE {
  Exchange = "exchange",
  Basket = "basket",
  BasketExchange = "all"
}

export const PARTNER_CATEGORIES: ICategoryObject[] = [
  { name: PARTNER_TYPE_NAME.BasketExchange, value: PARTNER_TYPE.BasketExchange },
  { name: PARTNER_TYPE_NAME.Exchange, value: PARTNER_TYPE.Exchange },
  { name: PARTNER_TYPE_NAME.Basket, value: PARTNER_TYPE.Basket },
];

