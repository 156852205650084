import { FC, useRef, useState } from "react";
import SearchBox from "../../../../UI/inputs/textInput/SearchBox";
import Button from "../../../../UI/buttons/Button";
import IconButton from "../../../../UI/buttons/IconButton";
import UploadIcon from "../../../../../assets/icons/UploadIcon";
import ShablonIcon from "../../../../../assets/icons/ShablonIcon";
import SpekaIcon from "../../../../../assets/icons/SpekaIcon";
import TrashIcon from "../../../../../assets/icons/TrashIcon";
import {
  IReference,
  UnllocatedReference,
} from "../../../../../assets/interfaces/IReference";
import ScrollArea from "../../../../UI/wrappers/ScrollArea";
import ReferenceCard from "../../../../ReferenceCard";
import AddRefModal from "../../../../modals/AddRefModal";
import ConfirmModal from "../../../../modals/ConfirmModal";
import {
  DownloadSpec,
  DownloadTemplate,
  UploadSpec,
} from "../../../../../API/base/exchangeApi/exchangeFunctions";
import { ResponseContainer } from "../../../../../API/BaseApi";
import {
  ImportError,
  uploadSpecResponse,
} from "../../../../../API/base/exchangeApi/exchangeInterfaces";
import ErrorModal from "../../../../UI/modals/ErrorModal";
import { ImportErrorModal } from "../../../../modals/ImportErrorModal";
import { toast } from "react-toastify";
import LoaderModal from "../../../../UI/loader/LoaderModal";
import Tooltip from "../../../../UI/Tooltip/Tooltip";

interface IProps {
  references: IReference[];
  scriptsLoaded: boolean;
  updateReferences: (refs: IReference[]) => void;
  onGetScenarios: () => void;
  unllocatedReferences?: UnllocatedReference[];
  scenarioIsOpen: boolean;
}

const OrderList: FC<IProps> = ({
  references,
  scriptsLoaded,
  updateReferences,
  onGetScenarios,
  unllocatedReferences,
  scenarioIsOpen,
}) => {
  const [addRedModal, setAddRefModal] = useState<boolean>(false);
  const [clearModalOpen, setClearModalOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>();
  const [loadFileError, setLoadFileError] = useState<boolean>(false);
  const [loadSpecData, setLoadSpecData] = useState<uploadSpecResponse>();
  const [fileLoaderKey, setFileLoaderKey] = useState<number>(0);

  const [uploadSpecIsLoading, setUploadSpecIsLoading] =
    useState<boolean>(false);

  const changeRefCount = (refSku: string, count: number) => {
    const newRefs = [...references];
    const currentIndex = newRefs.findIndex((r) => r.referenceSku === refSku);
    let currentRef = { ...newRefs[currentIndex], count: count };
    newRefs[currentIndex] = currentRef;
    updateReferences(newRefs);
  };

  const deleteRef = (refSku: string) => {
    const newRefs = references.filter((r) => r.referenceSku !== refSku);
    updateReferences(newRefs);
  };

  const addRef = (ref: IReference) => {
    const newRefs = [...references, { ...ref, count: 1 }];
    updateReferences(newRefs);
  };

  const clearRefs = () => {
    updateReferences([]);
    setClearModalOpen(false);
  };

  const showReferencesList = () => {
    if (!searchText) {
      return references.map((r: IReference) => (
        <ReferenceCard
          infoIcon={setReferenceCardIcon(r.referenceSku)}
          deleteRef={deleteRef}
          setCount={changeRefCount}
          key={r.referenceSku}
          renderPlace={"bascket"}
          data={r}
        />
      ));
    } else {
      return references
        .filter((r: IReference) =>
          r.referenceSku.toLowerCase().includes(searchText.toLowerCase())
        )
        .map((r: IReference) => (
          <ReferenceCard
            deleteRef={deleteRef}
            setCount={changeRefCount}
            key={r.referenceSku}
            renderPlace={"bascket"}
            data={r}
          />
        ));
    }
  };

  const setReferenceCardIcon = (referenceSku: string) => {
    if (scenarioIsOpen) {
      const unllocatedRefCount = unllocatedReferences?.find(
        (item: UnllocatedReference) => item.referenceSku === referenceSku
      )?.unllocatedCount;
      const basketRefCount = references.find(
        (item: IReference) => item.referenceSku === referenceSku
      )?.count;
      if (basketRefCount && unllocatedRefCount) {
        if (basketRefCount === unllocatedRefCount) {
          return { color: "text-system-red", text: "Референса нет в сценарии" };
        }
        if (basketRefCount > unllocatedRefCount) {
          return {
            color: "text-system-yellow",
            text: "Референс в сценарии присутствует частично",
          };
        }
      }
    }
  };

  const onUploadSpecFile = (file: any) => {
    const fileType = file.type;
    if (
      fileType ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setUploadSpecIsLoading(true);
      UploadSpec(file).then((r: ResponseContainer<uploadSpecResponse>) => {
        if (r.status !== "error") {
          if (r.data?.importErrors?.length === 0) {
            updateReferences(r.data?.references as IReference[]);
            setLoadSpecData(undefined);
          } else {
            setLoadSpecData(r.data);
          }
        }
        setUploadSpecIsLoading(false);
      });
    } else {
      setLoadFileError(true);
    }
    setFileLoaderKey(fileLoaderKey + 1);
  };

  const onDownloadSpec = () => {
    const refsData = references.map((r) => {
      return { referenceSku: r.referenceSku, count: r.count as number };
    });
    DownloadSpec({ references: refsData }, (msg: string) => {
      toast.error(msg);
    });
  };

  const onDownloadTemplate = () => {
    DownloadTemplate((msg: string) => toast.error(msg));
  };

  const onUpdateRefsWithImportErrors = () => {
    if (loadSpecData?.references && loadSpecData.references.length > 0) {
      updateReferences(loadSpecData?.references);
    } else {
      toast.error("Референсы не были загружены, т.к отсутствовали в файле");
    }
    setLoadSpecData(undefined);
  };

  const loadFileLabelRef = useRef<HTMLLabelElement>(null);
  const onRetryLoad = () => {
    document.getElementById("specFileLoad")?.click();
  };

  return (
    <div className="w-full h-full flex flex-col pt-5">
      <div className="w-full flex flex-col flex-1 overflow-hidden">
        <div className="w-full flex items-center mb-4 h-9 px-9">
          <h1 className="flex-1 text-lg font-semibold">Состав заказа</h1>
          {references.length > 0 && (
            <Button
              size="small"
              onClick={() => setClearModalOpen(true)}
              noFish
              className="!w-[142px] -mr-[21px] text-gray-3"
              variant="text"
            >
              <TrashIcon size={20} /> Очистить
            </Button>
          )}
        </div>
        <div className="flex w-full gap-4 mb-4 px-9">
          <SearchBox
            onClear={() => setSearchText(undefined)}
            onChangeText={setSearchText}
            placeholder="Поиск"
            inputSize="small"
            className="rounded-none border-r-0 border-l-0 border-t-0"
          />
          <Button
            onClick={() => setAddRefModal(true)}
            size="small"
            noFish
            className="rounded-lg !w-28"
          >
            + Референс
          </Button>
        </div>
        <div className="flex w-full flex-1 mb-1 items-center justify-center overflow-hidden pl-9 pr-1">
          {references.length > 0 ? (
            <ScrollArea className="pr-4">
              <div className="pr-4">{showReferencesList()}</div>
            </ScrollArea>
          ) : (
            <div className="flex flex-col gap-4 items-center">
              <p className="text-gray-3 text-base">Здесь пока ничего нет</p>
              <label
                ref={loadFileLabelRef}
                className="w-auto"
                htmlFor="specFileLoad"
              >
                <div className="rounded-lg bg-accent hover:bg-accent-dark text-white px-6 h-[46px] transition-all cursor-pointer flex items-center">
                  <span className="flex items-center gap-2">
                    <UploadIcon size={18} />
                    <p>Загрузить спецификацию</p>
                  </span>
                </div>
              </label>
            </div>
          )}
        </div>
      </div>
      <div className="w-full h-16 flex items-center shadow-4 bg-white px-9">
        <div className="gap-2 flex">
          <Tooltip text="Скачать шаблон">
            <IconButton onClick={onDownloadTemplate} variant="secondary">
              <ShablonIcon size={18} />
            </IconButton>
          </Tooltip>
          <label id="specFileLoadLabel" htmlFor="specFileLoad">
            <div
              className="w-9 h-9 flex items-center justify-center
                 cursor-pointer text-gray-3 rounded-lg bg-gray-1 hover:bg-accent-lite
                  hover:text-accent transition-all"
            >
              <Tooltip text="Загрузить спецификацию">
                <span>
                  <UploadIcon size={18} />
                </span>
              </Tooltip>
            </div>
          </label>
        </div>
        {references.length > 0 && (
          <div className="flex-1 flex items-center gap-2 justify-end">
            <Button
              onClick={onDownloadSpec}
              variant="text"
              size="small"
              className="w-[230px] rounded-lg"
              noFish
            >
              <SpekaIcon />
              Скачать спецификацию
            </Button>
            {!scriptsLoaded && (
              <Button
                onClick={onGetScenarios}
                size="small"
                className="w-[87px] rounded-lg"
                noFish
              >
                Заказать
              </Button>
            )}
          </div>
        )}
      </div>
      <input
        key={fileLoaderKey}
        onChange={(e: any) => {
          if (e.currentTarget.files[0]) {
            onUploadSpecFile(e.currentTarget.files[0]);
          }
        }}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        className="hidden"
        id="specFileLoad"
        type="file"
      />
      {addRedModal && (
        <AddRefModal
          isOpen={addRedModal}
          toggleOpen={() => setAddRefModal(!addRedModal)}
          addRef={addRef}
          deleteRef={deleteRef}
          changeRefCount={changeRefCount}
          addedRefsCount={references.map((r: IReference) => {
            return { count: r.count, referenceSku: r.referenceSku };
          })}
        />
      )}
      <ConfirmModal
        isOpen={clearModalOpen}
        toggleOpen={() => setClearModalOpen(!clearModalOpen)}
        headText="Очистить корзину?"
        text="Вы уверены, что хотите полностью очистить корзину?"
        confirmBtnText="Очистить"
        funk={clearRefs}
      />
      <ErrorModal
        headText="Не удалось загрузить файл"
        isOpen={loadFileError}
        text="Файл поврежден или имеет неверный формат"
        toggleOpen={() => setLoadFileError(!loadFileError)}
      />
      <ImportErrorModal
        skipFunk={onUpdateRefsWithImportErrors}
        retryFunk={onRetryLoad}
        open={
          typeof loadSpecData === "object" &&
          loadSpecData.importErrors &&
          loadSpecData.importErrors.length > 0
            ? true
            : false
        }
        errors={(loadSpecData?.importErrors as ImportError[]) || []}
        onOpenChange={() => setLoadSpecData(undefined)}
      />
      <LoaderModal open={uploadSpecIsLoading} />
    </div>
  );
};

export default OrderList;
